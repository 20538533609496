import { Injectable, NgZone } from '@angular/core';

import { ProcessErrorCode } from 'src/app/ajs-upgraded-providers';

import { StateService } from '@uirouter/angular';
import { ApiUtilsService } from 'src/app/api/services/api-utils.service';
import { PresentationApiService } from 'src/app/api/services/presentation-api.service';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { TrackerService } from 'src/app/components/logging/tracker.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import { EmbeddingPresentationsModalComponent } from 'src/app/editor/components/embedding-presentations-modal/embedding-presentations-modal.component';
import { PresentationsService } from 'src/app/editor/services/presentations.service';
import { FirstScheduleService } from 'src/app/schedules/services/first-schedule.service';
import { ScheduleSelectorService } from 'src/app/schedules/services/schedule-selector.service';
import { ScheduleService } from 'src/app/schedules/services/schedule.service';
import { BroadcasterService } from 'src/app/shared/services/broadcaster.service';
import { PromiseUtilsService } from 'src/app/shared/services/promise-utils.service';
import { BrandingService } from '../template-components/services/branding.service';
import { BlueprintService } from './blueprint.service';

@Injectable({
  providedIn: 'root'
})
export class TemplateEditorService {

  static readonly HTML_TEMPLATE_DOMAIN = 'https://widgets.risevision.com';
  static readonly REVISION_STATUS_PUBLISHED = 'Published';
  static readonly REVISION_STATUS_REVISED = 'Revised';
  static readonly HTML_PRESENTATION_TYPE = 'HTML Template';
  static readonly IGNORED_FIELDS = [
    'id', 'companyId', 'revisionStatus', 'revisionStatusName',
    'changeDate', 'changedBy', 'creationDate', 'publish', 'layout'
  ];

  hasUnsavedChanges = false;
  presentation: any = {};
  loadingPresentation;
  savingPresentation;
  errorMessage;
  apiError;

  constructor(private ngZone: NgZone,
    private stateService: StateService,
    private broadcaster: BroadcasterService,
    private modalService: ModalService,
    private presentationApiService: PresentationApiService,
    private presentationsService: PresentationsService,
    private processErrorCode: ProcessErrorCode,
    private apiUtils: ApiUtilsService,
    private userStateService: UserStateService,
    private firstScheduleService: FirstScheduleService,
    private brandingFactory: BrandingService,
    private blueprintFactory: BlueprintService,
    private scheduleService: ScheduleService,
    private trackerService: TrackerService,
    private scheduleSelectorService: ScheduleSelectorService,
    private promiseUtils: PromiseUtilsService) {
      this._init();
    }

    hasContentEditorRole() {
      return this.userStateService.hasRole('ce') || this.userStateService.hasRole('cp') || this.userStateService.hasRole('ap');
    }

    _parseJSON(json) {
      try {
        return JSON.parse(json);
      } catch (err) {
        console.error('Invalid JSON: ' + err);
        return null;
      }
    }

    _setPresentation(presentation, isUpdate?) {

      if (isUpdate) {
        this.presentation.id = presentation.id;
        this.presentation.companyId = presentation.companyId;
        this.presentation.revisionStatus = presentation.revisionStatus;
        this.presentation.revisionStatusName = presentation.revisionStatusName;
        this.presentation.credentials = presentation.credentials;
        this.presentation.creationDate = presentation.creationDate;
        this.presentation.changeDate = presentation.changeDate;
        this.presentation.changedBy = presentation.changedBy;
      } else {
        presentation.templateAttributeData =
          this._parseJSON(presentation.templateAttributeData) || {};

        this.presentation = presentation;
      }

      // Clear Web Page Authentication fields
      delete this.presentation.credentialsUpdate;

      this.broadcaster.emit('presentationUpdated');
    }

    _getPresentationForUpdate() {
      var presentationVal = JSON.parse(JSON.stringify(this.presentation));

      this.blueprintFactory.updateOrientation(presentationVal.templateAttributeData);

      presentationVal.templateAttributeData =
        JSON.stringify(presentationVal.templateAttributeData);

      if (this.presentation.credentialsUpdate) {
        presentationVal.credentialsUpdate = JSON.stringify(this.presentation.credentialsUpdate);
      }

      return presentationVal;
    }

    addFromProduct(productDetails, trackingData?: any) {
      this._clearMessages();

      this.presentation = {
        id: undefined,
        productCode: productDetails.productCode,
        name: productDetails.name,
        presentationType: TemplateEditorService.HTML_PRESENTATION_TYPE,
        templateAttributeData: productDetails.templateAttributeData ?? {},
        revisionStatusName: undefined,
        isTemplate: false,
        isStoreProduct: false
      };

      this.trackerService.presentationEvent('HTML Template Copied', productDetails.productCode, productDetails.name, trackingData);

      return this.blueprintFactory.getBlueprintCached(this.presentation.productCode)
        .then(this.save.bind(this))
        .then(null, (e) => {
          this._showErrorMessage('add', e);
          return Promise.reject(e);
        });
    }

    addPresentation() {
      var presentationVal = this._getPresentationForUpdate();

      return this.presentationApiService.add(presentationVal)
        .then( (resp) => {
          if (resp && resp.item && resp.item.id) {
            this.broadcaster.emit('presentationCreated');

            this._setPresentation(resp.item);

            if (!this.presentation.templateAttributeData || Object.keys(this.presentation.templateAttributeData).length === 0) {
              this.trackerService.presentationEvent('Presentation Created', resp.item.id, resp.item.name, {
                presentationType: 'HTML Template',
                sharedTemplate: resp.item.productCode
              });

              this.stateService.go('apps.editor.templates.edit', {
                presentationId: resp.item.id,
                productId: undefined,
              }, {
                location: 'replace'
              });
            }


            return Promise.resolve(resp.item.id);
          }
        });
    }

    copyPresentation() {

      this.trackerService.presentationEvent((this.presentation.isTemplate ? 'Template' : 'Presentation') + ' Copied',
        this.presentation.id, this.presentation.name, {
          presentationType: this.presentation.presentationType
      });

      this.presentation.id = undefined;
      this.presentation.name = 'Copy of ' + this.presentation.name;
      this.presentation.revisionStatusName = undefined;
      this.presentation.isTemplate = false;
      this.presentation.isStoreProduct = false;

      return this.blueprintFactory.getBlueprintCached(this.presentation.productCode)
        .then(() => {
          this.save();
        })
        .then(null, (e) => {
          this._showErrorMessage('copy', e);
          return Promise.reject(e);
        });
    }

    copySharedPresentation (presentationId) {
      return this.getPresentation(presentationId).then(() => {
        this.copyPresentation();
      });
    }

    updatePresentation() {
      if (!this.hasUnsavedChanges) {
        //Factory has no Changes.
        return Promise.resolve();
      }

      var presentationVal = this._getPresentationForUpdate();

      return this.presentationApiService.update(presentationVal.id, presentationVal)
        .then( (resp) => {
          this.trackerService.presentationEvent('Presentation Updated', resp.item.id, resp.item.name);

          this._setPresentation(resp.item, true);
          this.presentationsService.resetPresentation(resp.item);

          return Promise.resolve(resp.item.id);
        });
    }

    isUnsaved() {
      return !!(this.hasUnsavedChanges || this.brandingFactory.hasUnsavedChanges);
    }

    save() {
      var deferred = this.promiseUtils.generateDeferredPromise(),
        saveFunction;

      if (this.presentation.id) {
        saveFunction = this.updatePresentation.bind(this);
      } else {
        saveFunction = this.addPresentation.bind(this);
      }

      this._clearMessages();

      //show spinner
      this.loadingPresentation = true;
      this.savingPresentation = true;

      Promise.all([this.brandingFactory.saveBranding(), saveFunction()])
        .then( () => {
          deferred.resolve();
        })
        .then(null, (e) => {
          // If adding, and there is a Presentation Id it means save was successful
          // and the failure was to update Branding
          this._showErrorMessage(this.presentation.id ? 'update' : 'add', e);

          deferred.reject(e);
        })
        .finally( () => {
          this.loadingPresentation = false;
          this.savingPresentation = false;

          this.ngZone.run(() => {});
        });

      return deferred.promise;
    }

    getPresentation(presentationId?) {
      var deferred = this.promiseUtils.generateDeferredPromise();

      this._clearMessages();

      //show loading spinner
      this.loadingPresentation = true;

      this.presentationApiService.get(presentationId)
        .then( (result) => {
          this._setPresentation(result.item);

          return this.blueprintFactory.getBlueprintCached(this.presentation.productCode);
        })
        .then( () => {
          deferred.resolve();
        })
        .then(null, (e) => {
          this._showErrorMessage('get', e);
          this.presentation = {};
          this.blueprintFactory.blueprintData = null;

          deferred.reject(e);
        })
        .finally( () => {
          this.loadingPresentation = false;
        });

      return deferred.promise;
    }

    deletePresentation() {
      var deferred = this.promiseUtils.generateDeferredPromise();

      this._clearMessages();

      //show spinner
      this.loadingPresentation = true;
      this.savingPresentation = true;

      this.presentationApiService.delete(this.presentation.id)
        .then( () => {
          this.trackerService.presentationEvent('Presentation Deleted', this.presentation.id, this.presentation.name);

          this.broadcaster.emit('presentationDeleted');

          this.presentation = {};

          this.stateService.go('apps.editor.list');
          deferred.resolve();
        })
        .then(null, (e) => {
          let embeddingPromise: Promise<any> = Promise.reject(e);
          let error = this.apiUtils.getError(e);
          let msg = error.message || '';
``;
          if (msg.includes("used in one or more schedules") || msg.includes("embedded in one or more presentations")) {
            embeddingPromise = this._showEmbeddingItems();
            this._setErrorMessage('delete', e);
          }

          // Handle remaining presentation.delete errors or any error generated while loading embedding schedules/presentations
          embeddingPromise.catch(e => {
            this._showErrorMessage('delete', e);
          })
          .finally(() => {
            deferred.reject(e);
          });
        })
        .finally( () => {
          this.loadingPresentation = false;
          this.savingPresentation = false;

          this.ngZone.run(() => {});
        });

      return deferred.promise;
    }

    _showEmbeddingItems() {
      let presentationsPromise = this.presentationApiService.listEmbeddingPresentations(this.presentation.id);
      let schedulesPromise = this.presentationApiService.listEmbeddingSchedules(this.presentation.id);

      return Promise.all([presentationsPromise, schedulesPromise])
        .then(([presentations, schedules]) => {
          return this.modalService.showMediumModal(EmbeddingPresentationsModalComponent, {
            initialState: {
              presentation: this.presentation,
              embeddingPresentations: presentations.items || [],
              embeddingSchedules: schedules.items || []
            }
          })
          .catch(() => {}); // Do not trigger the fallback modal
        });
    }

    isRevised() {
      return this.presentation.revisionStatusName === TemplateEditorService.REVISION_STATUS_REVISED;
    }

    isPublishDisabled() {
      var isNotRevised = !this.isRevised() && !this.brandingFactory.isRevised() &&
      this.scheduleService.hasSchedules();

      return this.savingPresentation || this.isUnsaved() || isNotRevised;
    }

    publish() {
      return this._publish()
        .then(() => {
          return this.scheduleSelectorService.checkAssignedToSchedules();
        });
    }

    _publish() {
      var deferred = this.promiseUtils.generateDeferredPromise();

      this._clearMessages();

      //show spinner
      this.loadingPresentation = true;
      this.savingPresentation = true;

      var tasks = [this._publishPresentation()];
      if (!this.userStateService.hasRole('ap')) {
        tasks.push(this.brandingFactory.publishBranding());
      }

      Promise.all(tasks)
        .then( () => {
          deferred.resolve();
        })
        .then(null, (e) => {
          this._showErrorMessage('publish', e);

          deferred.reject();
        })
        .finally( () => {
          this.loadingPresentation = false;
          this.savingPresentation = false;

          this.ngZone.run(() => {});
        });

      return deferred.promise;
    }

    _publishPresentation() {
      if (!this.isRevised()) {
        // template is already published
        return this._createFirstSchedule();
      }

      return this.presentationApiService.publish(this.presentation.id)
        .then( () => {
          this.trackerService.presentationEvent('Presentation Published', this.presentation.id, this.presentation.name);

          this.presentation.revisionStatusName = TemplateEditorService.REVISION_STATUS_PUBLISHED;
          this.presentation.changeDate = new Date();
          this.presentation.changedBy = this.userStateService.getUsername();

          this.presentationsService.resetPresentation(this.presentation);
          this.broadcaster.emit('presentationPublished');

          return this._createFirstSchedule();
        });
    }

    _createFirstSchedule() {
      return this.firstScheduleService.create(this.presentation)
        .then(() => {
          return this.scheduleSelectorService.loadSelectedSchedules();
        })
        .catch( (err) => {
          return err === 'Already have Schedules' ? Promise.resolve() : Promise.reject(err);
        });
    }

    _setErrorMessage(action, e) {
      this.errorMessage = 'Failed to ' + action + ' Presentation.';
      this.apiError = this.processErrorCode('Presentation', action, e);

      console.error(this.errorMessage, e);
    }

    _showErrorMessage(action, e) {
      this._setErrorMessage(action, e);

      this.modalService.showMessage(this.errorMessage, this.apiError);
    }

    _clearMessages() {
      this.loadingPresentation = false;
      this.savingPresentation = false;

      this.errorMessage = '';
      this.apiError = '';
    }

    _init() {
      this.presentation = {};

      this._clearMessages();
    }

}
