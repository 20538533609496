import { Component, OnInit } from '@angular/core';
import { ModalComponent } from '../../../components/modals/modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import OpenAI from 'openai';
import { TemplateEditorService } from '../../services/template-editor.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { FileDownloader } from 'src/app/ajs-upgraded-providers';
import { StockLibraryFile } from '../../template-components/services/stock-library.service';
import { StorageUtilsService } from 'src/app/storage/services/storage-utils.service';

type PromptResult = {
  query: string;
  text: { title: string, heading: string, body: string }[];
};

@Component({
  selector: 'ai-prompt-modal',
  templateUrl: './ai-prompt-modal.component.html',
  styleUrl: './ai-prompt-modal.component.scss'
})
export class AiPromptModalComponent extends ModalComponent implements OnInit {

  promptText: string;
  results: any[];

  pixabayResults: any[];
  promptResult: PromptResult;
  presentations: any[];
  uploadManager: any;
  isUploading = false;
  fileUploadList: StockLibraryFile[];
  fileType = 'image';
  images: any[];
  loading = false;

  get presentationIds(): string[] {
    return this.results?.length > 0 ? this.results.map((result) => result.id) : [];
  }

  openai = new OpenAI({
    apiKey: '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',
    dangerouslyAllowBrowser: true
  });

  constructor(
    private templateEditorService: TemplateEditorService,
    private httpClient: HttpClient,
    private fileDownloader: FileDownloader,
    private storageUtilsService: StorageUtilsService,
    public modalRef: BsModalRef
  ) {
    super(modalRef);
  }

  ngOnInit(): void {
    this.uploadManager = {
      onUploadStatus: (isUploading) => {
        this.isUploading = isUploading;
        if (!isUploading) {
          this.createPresentations.bind(this)();
        }
      },
      addFile: (file) => {
        console.log('Added', file);
        console.log(this.pixabayResults);
        console.log(this.fileUploadList);
        if (!this.images.find((f) => f.name === file.name)) {
          this.images.push(file);
        }
      },
      isSingleFileSelector: () => false,
      skipOverwrite: true
    };
  }

  fontSize(text: string) {
    return Math.min(300, Math.max(24, Math.round(500 / text.length)));
  }

  apply() {
    if (!this.results || this.results.length === 0) {
      this.loading = true;
      const completion = this.openai.chat.completions.create({
        model: "gpt-4o-mini",
        messages: [
          { role: "system", content: "You are a template generator for digital display management platform. You generate unique and creative templates for users based on their prompts. For each prompt, you are responsible for generating: (1) a comma-separated list of search terms (maximum 90 characters) that will be used for searching a stock image service for relevant images; and (2) a total of three alternative sets of generated text, comprising a concise and attention-grabbing title (a maximum of three words long), a short and captivating headline (maximum five words), and a descriptive and engaging sentence that together capture the essence of the user's prompt. Return these outputs in JSON format with the following structure, replacing %TERMS% with the generated search query, %TITLE1%, %TITLE2% and %TITLE3% with the three generated titles, %HEADING1%, %HEADING2%, %HEADING3% with the three generated headings, and %BODY1%, %BODY2% and %BODY3% with the three generated body texts: `{ query: \"%TERMS%\"}, text: [ { title: \"%TITLE1%\", heading: \"%HEADING1%\", body: \"%BODY1%\" }, { title: \"%TITLE2%\", heading: \"%HEADING2%\", body: \"%BODY2%\" }, { title: \"%TITLE3%\", heading: \"%HEADING3%\", body: \"%BODY3%\" } ] }`." },
          {
            role: "user",
            content: this.promptText,
          }
        ],
      }).then((response) => {
        if (response?.choices?.length > 0) {
          let result = response.choices[0].message.content.trim();
          console.log(result);
          if (result.startsWith('```json')) {
            result = result.substring(7);
          }
          if (result.endsWith('```')) {
            result = result.substring(0, result.length - 3);
          }
          this.promptResult = JSON.parse(result);
          console.log(this.promptResult);

          // Fetch images from Pixabay
          this.fetchImages(this.promptResult.query);
        } else {
          console.log('No response from OpenAI');
        }
      });
    }
  }

  createPresentations() {
    console.log('Images', this.images);
    const presentations = [];
    this.promptResult.text.forEach((text, index) => {
      const title = text.title.split(' ');
      const startTitle = title.shift();
      const endTitle = title.join(' ');
      const image = this.images[index] ? this.storageUtilsService.getBucketName() + '/' + this.images[index].name : null;
      const presentation = {
        productCode: 'dad4c84716cbeca6efc1c6be7219ee92c430bd81', // Social Event
        name: `Generated Presentation ${index + 1}`,
        templateAttributeData: {
          components: [
            {
              "id": "mainTitleFirst",
              "color": "",
              "value": startTitle,
              "font": "",
              "formatting": "",
              "fontsize": this.fontSize(startTitle),
              "lineheight": "",
              "letterspacing": null,
              "textalign": "",
              "horizontalalign": ""
            },
            {
              "id": "mainTitleSecond",
              "color": "",
              "value": endTitle,
              "font": "",
              "formatting": "",
              "fontsize": this.fontSize(endTitle),
              "lineheight": "",
              "letterspacing": null,
              "textalign": "",
              "horizontalalign": ""
            },
            {
              "id": "locationTitleFirst",
              "color": "",
              "value": "",
              "font": "",
              "formatting": "",
              "fontsize": 37,
              "lineheight": "",
              "letterspacing": null,
              "textalign": "",
              "horizontalalign": ""
            },
            {
              "id": "LocationTitleSecond",
              "color": "",
              "value": text.heading,
              "font": "",
              "formatting": "",
              "fontsize": 37,
              "lineheight": "",
              "letterspacing": null,
              "textalign": "",
              "horizontalalign": ""
            },
            {
              "id": "activites-info",
              "color": "",
              "value": text.body,
              "font": "",
              "formatting": "",
              "fontsize": 37,
              "lineheight": "",
              "letterspacing": null,
              "textalign": "",
              "horizontalalign": ""
            },
            image ?
            {
              // TODO: Replace with actual image URL
              "id": "rise-image-01",
              "metadata": [
                {
                  "file": image,
                  "exists": true,
                  "time-created": "",
                  "thumbnail-url": "",
                }
              ],
              "files": [image],
            } : null
          ]
        }
      };

      presentations.push(presentation);
    });

    console.log(presentations);

    this.addPresentations(presentations).then(() => {
      console.log('Presentations generated');
      this.loading = false;
    });
  }

  selectTemplate() {
    super.resolve();
  }

  fetchImages(query: string) {
    const apiKey = environment.PIXABAY_API_KEY;
    const url = `https://pixabay.com/api/?key=${apiKey}&q=${encodeURIComponent(query.substring(0, 100))}&image_type=photo&per_page=3&safesearch=true`;

    if (this.pixabayResults) {
      this.createPresentations();
    } else {
      this.fileUploadList = [];
      this.images = [];
      this.httpClient.get(url, {
        observe: 'response',
        responseType: 'json'
      }).subscribe(
          async (response: any) => {
            const images = (response.body as any);
            if (images.hits && images.hits.length > 0) {
              this.pixabayResults = images.hits;
              for (let imageHit of this.pixabayResults) {
                this.storeImages(imageHit);
              }
              // this.createPresentations();

              console.log('Images fetched from Pixabay:', this.pixabayResults);
            } else {
              console.log('No images found for the query:', query);
            }
          },
          (error) => {
            console.error('Error fetching images from Pixabay:', error);
          }
        );
      }
  }

  storeImages(imageHit) {
    const pageURL = imageHit.pageURL.replace(/\/$/, '');
    // Images have descriptive pageURLs, but videos do not,
    // so we prepend the video's tags to make a more descriptive filename.
    const filename =
      (this.fileType === 'image' ? '' : this.parseTags(imageHit.tags) + '-') +
      pageURL.substring(pageURL.lastIndexOf('/') + 1) +
      (this.fileType === 'image' ? imageHit.largeImageURL.substring(imageHit.largeImageURL.lastIndexOf('.')) : '.mp4');

    this.fileDownloader(this.fileType === 'image'
      ? imageHit.largeImageURL
      : imageHit.videos.medium.url,
      'Stock Files/' + filename
    )
      .then((file) => {
        console.log('Downloaded', file);
        this.fileUploadList = [...this.fileUploadList, file];
      })
      .catch((err) => {
        console.error(`Could not upload stock ${this.fileType}.`, err);
      });
  }

  async addPresentations(presentations: any[]) {
    this.results = [];
    for (let presentation of presentations) {
      await this.templateEditorService.addFromProduct(presentation);
      const result = this.templateEditorService.presentation;
      console.log("add result", result);
      this.results.push(result);
    }
  }

  dismiss () {
    super.reject();
  }

  private parseTags(tags: string): string {
    return tags.split(',').map((item) => item.trim().replaceAll(' ', '-')).join('-');
  }
}
