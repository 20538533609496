import { Component, EventEmitter, Input, Output } from "@angular/core";
import { StateService } from "@uirouter/angular";

@Component({
  selector: 'template-suggestions',
  templateUrl: './template-suggestions.component.html',
  styleUrls: ['./template-suggestions.component.scss']
})
export class TemplateSuggestionsComponent {
  @Input() presentations: string[];
  @Output() selectTemplate: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private stateService: StateService,
  ) { }

  select(presentationId: string) {
    console.log(presentationId);
    const unused = this.presentations.filter(id => id !== presentationId);

    for (const presentation of unused) {
      // Todo: delete unused presentations
    }
    this.stateService.go('apps.editor.templates.edit', {
      presentationId
    }, {
      location: 'replace'
    });

    this.selectTemplate.emit();
  }
}