<div class="suggestions" [class.empty]="!presentations?.length">
  @for (item of presentations; track $index) {
    <div class="suggested-item">
      <template-editor-preview-holder-item [presentationId]="item" />
      <button type="button" class="btn btn-primary mt-2" (click)="select(item)">Use This</button>
    </div>
  } @empty {
    <img src="../images/placeholder-image.png" />
    <img src="../images/placeholder-image.png" />
    <img src="../images/placeholder-image.png" />
  }
</div>